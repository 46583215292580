import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StyledUnderline = styled.div`
  width: 50px;
  height: 4px;
  background: #2c5282;
  margin-top: 80px;
  margin-bottom: 22px;
`

const Types = ['Community Associations', 'Commercial/High-rise Buildings', 'Retail Shopping Centers', 'Industrial Complexes & Associations', 'Single Family Homes', 'Medical Office Buildings', 'Public Housing',]

const Serves = ['Individual Owners', 'Family Trusts', 'Corporations', 'Community Associations', 'Financial Institutions', 'Partnerships/Syndicators', 'Foreign Investors']

const PageAboutSection = () => (
  <StaticQuery 
    query={query}
    render={
      data => {
        const imageDataOne = data.imageOne.childImageSharp.fluid

        return (
          <div className='bg-gray-200'>
            <div className='lg:w-10/12 mx-auto py-20 sm:py-6 sm:px-4 md:py-12 md:px-8'>
              <h3 className='font-display text-gray-900 text-3xl sm:text-2xl leading-snug mb-8'>Interpacific Asset Management is an accredited professional property management company with experience in all types of commercial and residential properties, including:</h3>

              <div className='flex items-center sm:block'>
                <ul className='w-1/2 sm:w-full md:w-2/3 ml-6 sm:ml-0 md:ml-0 p-4'>
                  {Types.map((type, index) => {
                    return (
                      <li key={index} className='flex items-center text-gray-800 text-xl mr-8 sm:mr-0 mb-4'>
                        <svg className='fill-current text-blue-900 inline-block h-8 w-8 mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                        {type}
                      </li>
                    )
                  })}
                </ul>

                <Img fluid={imageDataOne} alt='los angeles cityscape' className='w-1/2 md:w-1/3 sm:w-full shadow-xl' />
              </div>              

              <div className='lg:flex block'>
                <div className='lg:w-1/2 w-full'>
                  <StyledUnderline></StyledUnderline>
                  <h3 className='font-display text-gray-900 text-2xl sm:text-xl leading-snug mb-8'>Interpacific Asset Management serves:</h3>

                  <ul className='ml-6 sm:ml-0 p-4'>
                    {Serves.map((serve, index) => {
                      return (
                        <li key={index} className='flex items-center text-gray-800 text-xl mr-8 sm:mr-0 mb-4'>
                          <svg className='fill-current text-blue-900 inline-block h-8 w-8 mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                          {serve}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>      
        )
      }
    }
  />
)

export default PageAboutSection

const query = graphql`
  query PageAboutSectionQuery {
    imageOne: file(relativePath: { eq: "header/about-page-header-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`