import React from "react"
import { graphql } from 'gatsby'
import PageContainer from "../containers/PageContainer"
import HeadData from "../data/HeadData"
import PageHeaderImageBg from '../headers/PageHeaderImageBg'
import PageHeaderText from "../headers/PageHeaderText"
import HeaderNav from "../navigation/HeaderNav"
import PageAboutSection from "../sections/PageAboutSection"
import HomeAffiliationSection from "../sections/HomeAffiliationSection"
import CTASection from "../sections/CTASection"

export default () => (
  <PageContainer>
    <HeadData 
      title='About Us' 
      description='Learn more about Interpacific Asset Management.' 
    />

    <PageHeaderImageBg>
      <HeaderNav />
      <PageHeaderText
        title='About Us'
      />
    </PageHeaderImageBg>

    <PageAboutSection />

    <HomeAffiliationSection />

    <CTASection />
  </PageContainer>
)

export const query = graphql`
  query AboutPageQuery {
    imageBg: file(relativePath: { eq: "header/about-page-header-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1900) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`