import React from "react"
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDiv = styled.div`
  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }

  .text-shadow-blue {
    text-shadow: 0 3px 3px #08174e;
  }

  .text-underline {
    text-decoration: underline;
  }

  .animate {
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`

const PageHeaderText = ({ title }) => (
  <StyledDiv className='container w-full flex flex-col items-center justify-center p-4'>
    <h1 className='font-display font-bold sm:text-4xl text-5xl text-white text-center leading-tight text-shadow-blue mt-8'>
      {title ? title : null}
    </h1>
  </StyledDiv>
)

PageHeaderText.propTypes = {
  title: PropTypes.string,
}

export default PageHeaderText