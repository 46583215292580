import React from 'react'
import { Link } from 'gatsby'
import NewLogo from '../common/NewLogo'
import HeaderMenuList from './MenuList/HeaderMenuList'

const HeaderNav = () => (
  <nav className="absolute top-0 left-auto right-auto w-full flex items-start justify-between flex-wrap bg-transparent p-4">

    <div className='w-40 lg:w-56'>
      <Link to='/'>
        <NewLogo />
      </Link>
    </div>

    <div className="sm:hidden md:hidden flex items-center">
      <HeaderMenuList />
    </div>
  </nav>
)

export default HeaderNav