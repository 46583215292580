import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 300px;

  @media (max-width:767px) {
    height: 400px;
  }

  @media (min-width:768px) and (max-width:1023px) {
    height: 250px;
  }
`

const CTASection = () => (
  <StaticQuery 
    query={query}
    render={
      data => {
        const imageDataDefault = data.imageBg.childImageSharp.fluid

        return (
          <StyledBackgroundImage
            fluid={imageDataDefault }
            backgroundColor={`#040e18`}
          >
            <div className='lg:w-10/12 mx-auto pt-20 sm:px-4 sm:pt-8 md:px-8 md:pt-8'>
              <h2 className='text-white text-4xl sm:text-3xl md:text-3xl font-bold mb-8'>We Deliver Comprehensive Management Services</h2>

              <div className='flex sm:block justify-between'>
                <p className='text-white text-xl md:text-lg sm:w-full w-8/12 md:w-7/12 sm:mb-6 md:mr-8'>We are your support team for the smooth, reliable and profitable management of your commercial property and residential property.</p>

                <Link to='/request-info'>
                  <button className='bg-white text-xl md:text-lg font-bold text-blue-700 hover:text-blue-900 border hover:border-blue-300 px-12 py-4 md:px-6 md:py-2 shadow-xl'>Request Information</button>
                </Link>
              </div>
            </div>
          </StyledBackgroundImage>
        )
      }
    }
  />
)

export default CTASection

const query = graphql`
  query CTASectionQuery {
    imageBg: file(relativePath: { eq: "sections/cta-bg-tinted.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`