import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const HomeAffiliationSection = () => (
  <StaticQuery 
    query={query}
    render={
      data => {
        const imageCacm = data.imageCacm.childImageSharp.fluid
        const imageIrem = data.imageIrem.childImageSharp.fluid
        const imageAmo = data.imageAmo.childImageSharp.fluid
        const imageCai = data.imageCai.childImageSharp.fluid

        return (
          <div className='bg-white'>
            <div className='flex sm:flex-col md:flex-col lg:flex-col items-center justify-between lg:w-10/12 mx-auto py-20 sm:px-4 md:px-8 md:py-12 sm:clearfix'>
              <div className='xl:mr-32'>
                <h2 className='font-display text-blue-900 text-2xl lg:text-xl font-bold sm:mb-12 md:mb-12 lg:mb-12'>Affiliations &amp; <br className='md:hidden lg:hidden' /> Accreditation</h2>
              </div>

              <div className='flex sm:flex-col items-center w-full justify-between space-x-6'>
                <Img fluid={imageCai} alt='CAI logo' className='w-64 sm:mb-6 sm:float-right' />
                <Img fluid={imageIrem} alt='IREM logo' className='w-64 sm:mb-6 sm:float-right' />
                <Img fluid={imageCacm} alt='CACM logo' className='w-64 sm:mb-6 sm:float-right' />
                <Img fluid={imageAmo} alt='AMO logo' className='w-48 sm:float-right' />
              </div>
            </div>
          </div>
        )
      }
    }
  />    
)

export default HomeAffiliationSection

const query = graphql`
  query HomeAffiliationSectionQuery {
    imageCacm: file(relativePath: { eq: "logos/cacm-gray.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageIrem: file(relativePath: { eq: "logos/irem-gray.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageAmo: file(relativePath: { eq: "logos/amo-gray.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageCai: file(relativePath: { eq: "logos/cai-gray.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`