import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const StyledBackgroundImage = styled(BackgroundImage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: 0% 50%;
  width: 100%;
  height: 300px;

  @media (max-width:767px) {
    height: 300px;
  }
`

const PageHeaderImageBg = ({ children, imageData, className }) => (
  <StaticQuery 
    query={query}
    render={
      data => {
        const imageDataDefault = data.imageBg.childImageSharp.fluid

        return (
          <StyledBackgroundImage
            className={className}
            fluid={ imageData ? imageData : imageDataDefault }
            backgroundColor={`#040e18`}
          >
            {children}
          </StyledBackgroundImage>
        )
      }
    }
  />
)

PageHeaderImageBg.propTypes = {
  children: PropTypes.node,
  imageData: PropTypes.object,
  className: PropTypes.string,
}

export default PageHeaderImageBg

const query = graphql`
  query PageHeaderImageBgQuery {
    imageBg: file(relativePath: { eq: "header/home-header-bg-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1900) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`