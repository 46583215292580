import React from 'react'
import { Link } from 'gatsby'
import Popup from "reactjs-popup"

const HeaderMenuList = () => (
  <div className="text-md">
    <Link to='/' key='1'>
      <button className="inline-block font-bold text-sm text-white hover:text-blue-800 bg-blue-700 hover:bg-white py-2 px-4 ml-4 shadow-xl">
        Home
      </button>
    </Link>

    <Popup
      trigger={
        <Link to='/about'>
          <button className="inline-block font-bold text-sm text-white hover:text-blue-800 bg-blue-700 hover:bg-white py-2 px-4 ml-4 shadow-xl">About</button>
        </Link>
      }
      position="bottom left"
      on="hover"
      closeOnDocumentClick
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      contentStyle={{ padding: "0px", border: "none", marginTop: "20px", marginLeft: "16px", }}
      arrow={false}
    >
      <div className="bg-gray-100 p-4 shadow-lg">
        <Link to='/about' className="block text-blue-900 mb-2 hover:underline cursor-pointer">Overview</Link>
        <Link to='/mission-statement' className="block text-blue-900 mb-2 hover:underline cursor-pointer">Mission Statement</Link>
        <Link to='/amo-management' className="block text-blue-900 hover:underline cursor-pointer">AMO Management</Link>
      </div>
    </Popup>

    <Popup
      trigger={
        <Link to='/community-associations'>
          <button className="inline-block font-bold text-sm text-white hover:text-blue-800 bg-blue-700 hover:bg-white py-2 px-4 ml-4 shadow-xl">Services</button>
        </Link>
      }
      position="bottom left"
      on="hover"
      closeOnDocumentClick
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      contentStyle={{ width: "250px", padding: "0px", border: "none", marginTop: "20px", marginLeft: "16px", }}
      arrow={false}
    >
      <div className="bg-gray-100 p-4 shadow-lg">
        <Link to='/community-associations' className="block text-blue-900 mb-2 hover:underline cursor-pointer">Community Associations</Link>
        <Link to='/services-commercial' className="block text-blue-900 mb-2 hover:underline cursor-pointer">Commercial Services</Link>
        <Link to='/services-residential' className="block text-blue-900 hover:underline cursor-pointer">Residential Services</Link>
      </div>
    </Popup>

    <Link to='/owner-portal' key='4'>
      <button className="inline-block font-bold text-sm text-white hover:text-blue-800 bg-blue-700 hover:bg-white py-2 px-4 ml-4 shadow-xl">HOA Owner Portal</button>
    </Link>

    <Link to='/contact' key='4'>
      <button className="inline-block font-bold text-sm text-white hover:text-blue-800 bg-blue-700 hover:bg-white py-2 px-4 ml-4 shadow-xl">Contact</button>
    </Link>

    <Link to='/request-info' key='5'>
      <button className="inline-block font-bold text-sm text-blue-700 hover:text-blue-900 border hover:border-blue-300 bg-white py-2 px-4 ml-4 shadow-xl">Request Information</button>
    </Link>
  </div>
)

export default HeaderMenuList